@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Plus+Jakarta+Sans:wght@200;400;500;600;700;800&display=swap');
*{
  font-family: 'Inter', sans-serif;
}
html {
  scroll-behavior: smooth;
}
.App {
  font-family: 'Inter', sans-serif;
  text-align: center;
  // background: linear-gradient(165deg, #060606, #0C0C0C, #060606);
  // background-size: 200% 200%;
  // animation: gradient 5s ease infinite;
  // padding-bottom: 15px ;
  // margin: 0 15px;
  // border-bottom-left-radius: 8px;
  // border-bottom-right-radius: 8px;
  p{
    font-weight: 600;
  }
}

.VitaliPisani{
  text-align: center;
  margin: 0 auto;
  max-width: 300px;
    min-width: 100px;
    margin-bottom: -105px;
}

.container-title{
  max-width: 1000px;
  margin: 0 auto;  // Centra il container
text-align: left;
  h2{
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 800!important;
    font-size: 40px;
    margin-top: -15px;
    line-height: 40px;
   
  }
}

h2{
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 800!important;
  font-size: 40px;
  margin-top: -15px;
  line-height: 40px;
 
}

@media (max-width: 768px) {
  h2{
  
    font-size: 35px;
  
   
  }
 
}


@media (max-width: 468px) {
  h2{
  
    font-size: 32px;
  
   
  }
 
}


.portfolio-container-special{
  max-width: 1000px;
  margin: 0 auto;
 width: 100vw;
 margin-bottom: 66px;
 img{
  max-width: 1000px;
 
  width: 100%;
  border-radius: 15px;

  @media (max-width: 768px) {
  
    width: 96%;
  }
 

 }
 img:hover {
  transform: scale(1.04)!important; 
  transition: 0.2s!important;
}
img:active {
  transform: scale(0.9)!important;  
  
}
}



@media (max-width: 349px) {
  h2{
  
font-size: 29px;
  
   
  }
 
}


@media (max-width: 768px) {

  .mobile-br{
    margin-top: -15px;
  }

}

.progr{
  max-width: 550px;
  margin-bottom: 35px;
  margin-top: -10px;
  @media (max-width: 768px) {
    max-width: 90vw;
   
  }
}


::-webkit-scrollbar {
  width: 12px; /* Adjust width of scrollbar here */
}

::-webkit-scrollbar-track {
  background-color: #060606;
}

::-webkit-scrollbar-thumb {
  background-color: #f5f5f7;
  border-radius: 100px;
}

body {
  box-sizing: border-box;
  background-color: #060606;
  color: #f5f5f7;
  overflow-x: hidden;
  // &:after {
  //   content: "";
  //   background-image: url("./assets/noise.png");
    
  //   opacity: 0.01; 
  //   top: 0;
  //   left: 0;
  //   bottom: 0;
  //   right: 0;
  //   position: fixed;
  //   z-index: -1;
  // }
  
}

.loading-vitali{
  h1{
    margin-bottom: -15px;
  }
  div{
    
    justify-content: space-between;
  }
  .circle-loading{
    width: 30px;
  height: 30px;
  background-color: #f5f5f7; 
  border-radius: 115px;
 
  }
}


.expertise-container{

  width: 100%;
  .expertise-vitali-pisani{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
  height: 70px;
  max-width: 400px;
    scale: 0.9;
    img{
     
    scale: 0.2;
    }
  }
}


.portfolio-container {
  display: grid;
  grid-template-columns: 1fr;  // Una colonna per dispositivi mobile
  gap: 15px;  // Spazio tra le immagini
  max-width: 1000px;
  margin: 0 auto;  // Centra il container
  margin-bottom: 90px;

  img {
    width: 100%;  // Rende le immagini responsive
    height: auto;  // Mantiene l'aspetto delle immagini
    border-radius: 8px;  // Opzionale: angoli arrotondati
    display: block;  // Assicura che le immagini siano bloccate
    margin: 0 auto;  // Centra le immagini orizzontalmente
  }
}

@media (max-width: 768px) {
  .portfolio-container {
    

    img {
  max-width: 600px!important;
    }
    
  }
}


// Media query per gestire il layout a 3 colonne su desktop
@media (min-width: 768px) {
  .portfolio-container {
    grid-template-columns: repeat(3, 1fr);  // Tre colonne per dispositivi desktop

    img:hover {
      transform: scale(1.04);  // Effetto di ingrandimento solo su desktop
      transition: 0.2s;
    }
    img:active {
      transform: scale(0.9);  // Effetto di ingrandimento solo su desktop
      
    }
  }
}


.project-button {
  z-index: 114;
  position: fixed;  /* Posizione fissa */
  bottom: 0;  /* Distanza dal fondo */
  right: 20px;  /* Distanza dalla destra */
  // padding: 10px 20px;  /* Padding intorno al testo */
  font-size: 12px;  /* Dimensione del testo */
  // background-color: #1E1E1E;  /* Colore di sfondo */
  color: white;  /* Colore del testo */
  border: none;  /* Rimuove il bordo */
  border-radius: 105px;  /* Angoli arrotondati */
  cursor: pointer;  /* Cambia il cursore al passaggio del mouse */
  @media (max-width: 1600px) {
    display: none;
   
  }

}




@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 80% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}


.btt-cont{

  margin-top: -50px;
  text-align: center;
}


.btt-EV1{
margin-bottom: 5px;
  color:  #f5f5f7;
  background-color: hsl(0deg 0% 25.68% / 38.4%);
  padding: 15px 55px;
  border-radius: 15px;
  font-family: 'Plus Jakarta Sans', sans-serif!important;
  font-weight: 800!important;
  font-size: 15px;
  border: none;
  &:hover{
    scale: 0.99;
    background-color: #111111;
  }
  &:active{
    scale: 0.98;
  }
}
.btt-EV{
  // background: linear-gradient(45deg, rgb(29, 29, 29), black, #a5a5a5);
  // background-size: 200% 200%;
  // animation: gradient 5s ease infinite;
  border:none;
  color:  #060606;
  background-color: #f5f5f7;
  padding: 15px 25px;
  border-radius: 15px;
  font-family: 'Plus Jakarta Sans', sans-serif!important;
  font-weight: 800!important;
  font-size: 15px;
  border: none;
  &:hover{
    scale: 0.99;
  }
  &:active{
    scale: 0.98;
  }
}

hr{
  opacity: 0.07;
}



iframe{
  width: 50%;
  @media (max-width: 700px) {
    width: 97%;
    height: 300px;
   
  }
}