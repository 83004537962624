/* Navbar.css */
.navbar {
  z-index: 113;
  position: fixed;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  border-radius: 118px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.445);
}

.navbar button {
  margin-left: 5px;
  background-color: white;
  color: black;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-weight: 700;
  font-size: 16px;
  transition: background-color 0.3s, color 0.3s; /* Transizione fluida dei colori */
}

.navbar button:hover {
  background-color: black;
  color: white;
  border-radius: 100px;
}

/* Stile per mobile */
@media (max-width: 768px) {
  .navbar {
  }
  .navbar button {
    font-size: 14px !important;
  }
}

.navbar button.selected {
  background-color: black;
  color: white;
  border-radius: 4px;
}

/* ScrollToTopButton.css */
.scroll-to-top-button {
  background-color: rgb(219, 219, 219) !important; /* Colore di sfondo */
  border: none;
  color:  #060606!important; /* Colore del testo */
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 400px !important; /* Angoli arrotondati */
}
