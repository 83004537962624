/* CustomCursor.css */
body {
  cursor: none; /* Nasconde il cursore predefinito */
}

.cursor {
  z-index: 117;
  width: 30px;
  height: 30px;
  border-radius: 50%; /* Rende il div circolare */
  position: absolute;
  transform: translate(
    -50%,
    -50%
  ); /* Centra il cursore rispetto alle coordinate del mouse */
  background-color: #f5f5f7; /* Colore del cursore */
  pointer-events: none; /* Evita che il cursore interferisca con gli elementi della pagina */
  mix-blend-mode: difference;
  transition: transform 0.2s ease-out;

}

.cursor-moving {
  transform: translate(-50%, -50%) scale(1); /* Ingrandisce il cursore quando si muove */
}


@media (max-width: 768px) {

  .cursor {
   display: none;
}}