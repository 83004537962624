// Modal.scss
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.724);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 116;
    backdrop-filter: blur(10px); /* Aggiunto effetto di sfocatura */
  }
  
  .amplify-button--primary {
    text-align: center!important;
border-radius: 100px!important;
    max-width: 489px!important;
  
    min-width: 180px!important;
    // width: 420px!important;
    // background-color: #494039!important;
    color:  #111111!important;
    background-color: #f5f5f7!important;
    margin-top: 17.5px!important;
    margin-bottom: -17px!important;
    &:hover{
        scale: 0.99;
        background-color: #111111;
      }
      &:active{
        scale: 0.8;
      }
  }
  
.amplify-flex{
    label{
        color: white!important;
        font-weight: 700!important;
        font-family: 'Inter', sans-serif!important;
       
    }
}

  .amplify-input {
    color: white!important;
    font-weight: 700!important;
    font-family: 'Inter', sans-serif!important;
    text-align: left!important;
    border-radius: 4px!important;
  }
  

  .amplify-textfield {
    --amplify-components-fieldcontrol-color: white!important;
    --amplify-components-fieldcontrol-border-color: rgba(255, 255, 255, 0.746)!important;
    // --amplify-components-fieldcontrol-font-size: var(--amplify-components-textfield-font-size);
    --amplify-components-fieldcontrol-focus-border-color: white!important;
}

.amplify-label {
color: white!important;
}

  .modal {
    text-align: left;
    background-color: #0a0a0a;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 666px;
  }
  
  .close-button {
    color: white;
    // position: absolute;
    // top: 10px;
    // right: 10px;
     background-color: #111111;
     padding: 2.5px 15px;
    border: none;
    font-size: 1.5rem;
    border-radius: 400px;
    // cursor: pointer;
  
      &:active{
        scale: 0.8;
      }
  }
  
  .submit-button {
    background-color: #008CBA;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  